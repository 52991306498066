import * as React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

const PoolsPage = () => (
  <Layout>
    <Seo title="BLUME Pools" />
    <h1>BLUME Pools</h1>
    <div class="pgc">
    <p>
      The BLUME Pools provide liquidity for the BLUME Swap.
    </p><p>
      The BLUME Swap uses a custom pricing function different from the usual constant product (CPMM).
      The pricing function is derived from latest research in the field, and in cooperation
      with authors of research papers
      such as <a class="lrg" target="_blank"
        href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3805750">Park</a>, &nbsp;
      <a class="lrg"  target="_blank"
        href="https://arxiv.org/abs/2003.10001">Angeris/Chitra</a> and &nbsp;
      <a class="lrg"  target="_blank"
        href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3674178">Aoyagi</a>.
    </p><p>
      The benefit is fairer compensation of liquidity providers, as compared to CPMM.
    </p><p>
      For the purposes of the Hackathon, there is only one pool: SOL/BLM.
    </p><p>
      The reserves are assumed to be:
    </p><p>
      <b>100 SOL and 700 BLM</b>.
    </p><p>
      Providing and withdrawing liquidity will be enabled in a later version.
    </p>
    </div>
  </Layout>
)

export default PoolsPage
